import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'MainView',
    component: () => import(/* webpackChunkName: "about" */ '../views/Main.vue')
  },
  {
    path: '/prediction_pre',
    name: 'PredictionPre',
    component: () => import(/* webpackChunkName: "about" */ '../views/Prediction_pre.vue')
  },
  {
    path: '/prediction',
    name: 'Prediction',
    component: () => import(/* webpackChunkName: "about" */ '../views/Prediction.vue')
  },
  {
    path: '/situation_pre',
    name: 'SituationPre',
    component: () => import(/* webpackChunkName: "about" */ '../views/Situation_pre.vue')
  },
  {
    path: '/situation',
    name: 'Situation',
    component: () => import(/* webpackChunkName: "about" */ '../views/Situation.vue')
  },
  {
    path: '/cube',
    name: 'Cube',
    component: () => import(/* webpackChunkName: "about" */ '../views/Cube.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
