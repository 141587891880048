import { createStore } from 'vuex'

export default createStore({
  state() {
    return {
      is_login: false,
    }
  },
  getters: {
  },
  mutations: {
    login : function(state, l){
      state.is_login = l;
      window.sessionStorage.setItem('is_login', l);
    },
  },
  actions: {
  },
  modules: {
  },
})
