import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import LoadScript from 'vue-plugin-load-script';
// import printJS from 'print-js';
// import Particles from "particles.vue3"
// import particles from '../public/js/particles.min.js'
const isLogin = window.sessionStorage.getItem('is_login') === 'true';
store.commit('login', isLogin);
createApp(App).use(store).use(router).use(LoadScript).mount('#app')
// .loadScript("../../public/js/jquery-1.12.4.js").loadScript("../../public/js/particles.min.js")